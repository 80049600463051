<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import { VXETable } from 'vxe-table';

let formData;
export default {
  extends: Form,
  components: {

  },
  data() {
    return {
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('date_form');
  },

  methods: {
    setRule(v) {
      const rowData = v;
      return rowData;
    },

    formComplete() {

    },
    // 打印
    beginPrint() {
      let detail = null;
      request
        .post('/dms/dms/contract/queryAuthorizeData', {
          code: this.formConfig.row.code,
        })
        .then((res) => {
          if (res.code === 200) {
            detail = res.result;
            const isShow = detail.authorizationCode.split('-')[1].length === 3 && detail.authorizationCode.split('-')[1] <= '620';
            let html = null;
            if (!isShow) {
              html = `<div style="margin:200px 100px 0 100px;font-size:24px;">
                  <div style="text-align: right;font-size:26px;margin-right:40px;">(编号：<span>${detail.authorizationCode}</span>)</div>
                  <p style="line-height:60px;">兹委托<span> ${detail.cusName} </span> 销售西凤酒系列产品，产品明细及其销售区域以经销商合同为准。</p>
                  <p style="margin-left:90px;margin-bottom: 40px;">有效期为<span> ${detail.authorizationStartTime} </span>至<span> ${detail.authorizationEndTime} </span></p>
                  <p style="margin-left:70px;">特此委托</p>
                  <p style="text-align: right;margin-right:80px;"><span>${detail.ext16}</span></p>
                  <p style="text-align: right;margin-right:80px;"><span>${detail.authorizationTime}</span></p>
                  </div>`;
            } else {
              html = `<div style="margin:200px 100px 0 100px;font-size:24px;">
                  <div style="text-align: right;font-size:26px;margin-right:40px;">(编号：<span>${detail.authorizationCode}</span>)</div>
                  <p style="line-height:60px;">兹委托<span> ${detail.cusName} </span> 销售西凤酒系列产品，销售区域以经销商合同为准。</p>
                  <p style="margin-left:90px;margin-bottom: 40px;">有效期为<span> ${detail.authorizationStartTime} </span>至<span> ${detail.authorizationEndTime} </span></p>
                  <p style="margin-left:70px;">特此委托</p>
                  <p style="text-align: right;margin-right:80px;"><span>${detail.ext16}</span></p>
                  <p style="text-align: right;margin-right:80px;"><span>${detail.authorizationTime}</span></p>
                  </div>`;
            }
            VXETable.print({
              sheetName: '授权委托书',
              style: 'body   {font-family: SimSun;}',
              content: html,
            });
          } else {
            this.$message.error(res.messge);
          }
        });
    },
    CNDateString(date) {
      const cn = ['〇', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      const s = [];
      const newDate = date.split('-');
      const YY = newDate[0];
      for (let i = 0; i < YY.length; i += 1) {
        if (cn[YY.charAt(i)]) s.push(cn[YY.charAt(i)]);
        else s.push(YY.charAt(i));
      }
      s.push('年');
      const MM = Number(newDate[1]);
      if (MM < 10) s.push(cn[MM]);
      else if (MM === 10) s.push('十');
      else if (MM < 20) s.push(`十${cn[MM % 10]}`);
      s.push('月');
      const DD = Number(newDate[2]);
      if (DD < 10) s.push(cn[DD]);
      else if (DD === 10) s.push('十');
      else if (DD === 20) s.push('二十');
      else if (DD < 20) s.push(`十${cn[DD % 10]}`);
      else if (DD > 20 && DD < 30)s.push(`二十${cn[DD % 10]}`);
      else if (DD === 30) s.push('三十');
      else if (DD > 30) s.push(`三十${cn[DD % 10]}`);
      s.push('日');
      return s.join('');
    },
    submit() {
      const params = this.getFormData();
      //   const today = new Date();
      //   const year = today.getFullYear();
      //   const month = today.getMonth() + 1;
      //   const day = today.getDate();
      //   const formattedDate = `${year}-${month}-${day}`;
      const startTime = this.formConfig.row.ext39.split(' ')[0];
      const { endTime } = this.formConfig.row;
      params.authorizationTime = this.CNDateString(params.authorizationTime);
      const authorizationStartTime = this.CNDateString(startTime);
      const authorizationEndTime = this.CNDateString(endTime);
      console.log(params.authorizationTime, authorizationStartTime, authorizationEndTime);
      this.$confirm('是否确认打印授权委托书？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request
            .post('/dms/dms/contract/authorizeEntrust', {
              ...params,
              id: this.formConfig.row.id,
              authorizationStartTime,
              authorizationEndTime,
            })
            .then((res) => {
              if (res.code === 200) {
                this.$emit('onClose');
                this.$emit('onGetList');
                this.beginPrint();
              } else {
                this.$message.error(res.messge);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
  },
};
</script>
