<!--
1. 新增编辑表单- form 文件下
2. 移除商品功能的弹窗列表 - product-table 文件下
-->

<script>
import TablePage from '@/found/components/table_page';
import Form from '@/found/components/form';
import Vue from 'vue';
import { VXETable } from 'vxe-table';
import { ProcessLog } from '@/found/modules';
import FormPage from '../form/form'; // 新增表单
import productTable from '../product-table/product-table'; // 移除商品列表
import audit from '../audit/audit'; // 审核
import DateForm from '../form/detail.vue';
import ContractClosureForm from '../form/contract_closure.vue';
import proFail from '../form/proFail';
import * as utils from '../../../../../../found/utils/index';
import failureLog from '../form/failure_log';

export default {
  extends: TablePage,
  components: {
    FormPage,
    productTable,
    audit,
    DateForm,
    ContractClosureForm,
    ProcessLog,
    proFail,
    Form,
    failureLog,
  },
  data() {
    return {
      formShow: false,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('contract-manage-list');
  },

  methods: {
    clickVisible({
      row,
      val: { code },
    }) {
      const {
        ext11, ext3, processInstanceId, ext37, ext38, ext13, templateCode,
      } = row;
      // 审批通过 approved,审批中 approving,活动关闭 closed,流程追回 interrupt,驳回 rejected
      if (ext11 && code === 'edit' && (ext11 === '2' || ext11 === '1')) {
        return false;
      }
      if (code === 'remove_product_row' && (ext3 !== '1')) {
        return false;
      }
      if (code === 'work-flow-deal' && !processInstanceId) {
        return false;
      }
      if ((code === 'power_of_attorney' || code === 'cancel_power_of_attorney' || code === 'power_of_attorney_again') && ext11 !== '2') {
        return false;
      }
      if (code === 'power_of_attorney' && ext37 === 'Y') {
        return false;
      }
      if (code === 'cancel_power_of_attorney' && ext37 !== 'Y') {
        return false;
      }
      if (code === 'power_of_attorney_again' && ext37 !== 'Y') {
        return false;
      }
      if (code === 'sign_and_receive_paper_contracts' && ext38 === 'Y') {
        return false;
      }
      if (code === 'contract_closure' && (ext3 !== '1' || ext11 !== '2')) {
        return false;
      }
      if (code === 'process_log' && !templateCode) {
        return false;
      }
      return true;
    },
    setColumn(v) {
      const rows = v;
      if (rows.field === 'cusName') { // 客户名称
        rows.isSearch = true;
        rows.apiUrl = '/mdm/mdmCustomerMsgExtController/pageList';
        rows.paramsName = 'customerName';
        rows.optionsKey = {
          label: 'customerName',
          value: 'customerName',
        };
      }
      if (rows.field === 'ext16') { // 公司主体
        rows.isSearch = true;
        rows.paramsName = 'companyName';
        rows.apiUrl = '/mdm/companyInfo/companyPageList';
        rows.optionsKey = {
          label: 'companyName',
          value: 'companyName',
        };
      }
      return rows;
    },

    modalClick({
      val,
      row,
    }) {
      const formPageArg = {
        drawerTitle: '',
        code: val.code, // 按钮对应code
        dataCode: '', // 数据code
        formShow: true,
        row,
      };
      switch (val.code) {
        case 'add':
          formPageArg.drawerTitle = '新增';
          break;
        case 'view':
          formPageArg.drawerTitle = '查看';
          break;
        case 'edit':
          formPageArg.drawerTitle = '编辑';
          break;
        case 'remove_product_row':
          formPageArg.drawerTitle = '移除商品';
          break;
        case 'change_person':
          formPageArg.drawerTitle = '变更负责人';
          break;
        default:
          formPageArg.drawerTitle = '新增';
      }
      const codes = ['view', 'edit', 'remove_product_row', 'change_person'];
      if (codes.includes(val.code)) {
        formPageArg.dataCode = row.code;
      }
      console.log(val.code);
      const formCodes = ['add', 'edit', 'view', 'change_person'];
      if (formCodes.includes(val.code)) {
        const com = val.code !== 'remove_product' ? FormPage : productTable;
        this.createPage(formPageArg, com);
      }
      if (val.code === 'work-flow-deal') {
        console.log(formPageArg, row);
        this.createAudit(formPageArg, row);
      }
      if (val.code === 'power_of_attorney') {
        this.formName = 'DateForm';
        this.modalConfig = {
          title: '选择时间',
        };
        this.modalConfig.width = '400px';
        this.modalConfig.height = '200px';
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.openModal();
      }
      if (val.code === 'power_of_attorney_again') {
        this.beginPrint(row);
      }
      if (val.code === 'contract_closure') {
        this.formName = 'ContractClosureForm';
        this.modalConfig = {
          title: '合同关闭',
          width: '80%',
        };
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.openDrawer();
      }
      if (val.code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row: { processNo: row.templateCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
      if (val.code === 'export_list') {
        console.log(val);
        this.modalName = 'ExportModal';
        this.propsObjInData = {
          functionCode: val.doCode ? val.doCode : val.functionCode,
          requestUrl: val.queryUrl ? val.queryUrl : this.requestUrl, // 非导入导出文案的导入导出功能，查询数据接口不是列表接口，可通过查询接口传入
          visible: true,
          exportMessage: '',
          isExport: true,
          ids: this.selection,
          formData: { ...this.params, ...this.searchFormData, ...this.exportParams },
          sid: utils.getUuid(32, 16),
        };
      }
      if (val.code === 'pro_fail') {
        this.formName = 'proFail';
        this.modalConfig = {
          title: '选择失效产品',
        };
        this.modalConfig.width = '1000px';
        this.modalConfig.height = '600px';
        this.modalConfig.buttons = {
          sure: true, // 确定按钮
          close: true, // 关闭按钮
        };
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.openModal();
      }
      if (val.code === 'failure_log') {
        this.formName = 'failureLog';
        this.openFull();
      }
    },
    // 打印
    beginPrint(row) {
      const isShow = row.authorizationCode.split('-')[1].length === 3 && row.authorizationCode.split('-')[1] <= '620';
      let html = null;
      if (!isShow) {
        html = `<div style="margin:200px 100px 0 100px;font-size:24px;">
            <div style="text-align: right;font-size:26px;margin-right:40px;">(编号：<span>${row.authorizationCode}</span>)</div>
            <p style="line-height:60px;">兹委托<span> ${row.cusName} </span> 销售西凤酒系列产品，产品明细及其销售区域以经销商合同为准。</p>
            <p style="margin-left:90px;margin-bottom: 40px;">有效期为<span> ${row.authorizationStartTime} </span>至<span> ${row.authorizationEndTime} </span></p>
            <p style="margin-left:70px;">特此委托</p>
            <p style="text-align: right;margin-right:80px;"><span>${row.ext16}</span></p>
            <p style="text-align: right;margin-right:80px;"><span>${row.authorizationTime}</span></p>
            </div>`;
      } else {
        html = `<div style="margin:200px 100px 0 100px;font-size:24px;">
            <div style="text-align: right;font-size:26px;margin-right:40px;">(编号：<span>${row.authorizationCode}</span>)</div>
            <p style="line-height:60px;">兹委托<span> ${row.cusName} </span> 销售西凤酒系列产品，销售区域以经销商合同为准。</p>
            <p style="margin-left:90px;margin-bottom: 40px;">有效期为<span> ${row.authorizationStartTime} </span>至<span> ${row.authorizationEndTime} </span></p>
            <p style="margin-left:70px;">特此委托</p>
            <p style="text-align: right;margin-right:80px;"><span>${row.ext16}</span></p>
            <p style="text-align: right;margin-right:80px;"><span>${row.authorizationTime}</span></p>
            </div>`;
      }
      VXETable.print({
        sheetName: '授权委托书',
        style: 'body   {font-family: SimSun;}',
        content: html,
      });
    },
    createPage(arg, page) {
      const Component = Vue.extend(page);
      const that = this;
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('contract-manage-list');
          },
        },
      });
      this.vm1 = vm;
      document.body.appendChild(vm.$el);
    },
    createAudit(arg, row) {
      const Component = Vue.extend(audit);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
            row: { ...row },
          };
        },
      });
      this.vm1 = vm;
      document.body.appendChild(vm.$el);
    },
  },
  deactivated() {
    document.body.removeChild(this.vm1.$el);
  },
};
</script>
